import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="introduction">
        <h1>welcome <span className="small">by</span> ellie jo</h1>
        <p>a developer garage</p>
        <p className="small">coming soon...</p>
      </div>
    </div>
  );
}

export default App;
